export default defineProviderPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig();

  nuxtApp.$store.watch((state) => state.provider?.launchComplete, onLaunchComplete);

  function onLaunchComplete() {
    // gameOrigin - origin future iframe messages will be posted from
    let message = {
      method: "game.loaded",
      data: {
        gameOrigin: config.hlg.url,
      },
    };

    // TODO: "*" should be config.hlg.event_origin even if we set the config to * itself.
    window.parent.postMessage(message, "*");

    // Watches for messages that should only be sent after loadComplete sent
    // ...

    // Sync any state with provider
    // ...
  }

  function dispatchWalletMessage(walletMessage: any) {
    const message = {
      method: "game.handleWalletMessage",
      data: {
        message: walletMessage,
      },
    };

    console.debug(`Dispatch wallet message: ${walletMessage}`);
    window.parent.postMessage(message, "*");
  }

  // Provider Initiated Event Handlers
  window.addEventListener("message", async (event) => {
    // We have no way to test iForium's messaging and these would appear to be incorrect tests anyway.
    // As the messages are pose no security risk, these checks are getting disabled for now.

    // if (!ctx.env.HLG_EVENT_ORIGIN || event.origin != ctx.env.HLG_EVENT_ORIGIN) {
    //   console.warn(`Ignoring received message from unsupported origin ${event.origin}`)
    //   return
    // }

    try {
      switch (event.data.method) {
        case "gel.balance.reload":
          await nuxtApp.$store.dispatch("provider/refreshBalance");
          break;
        case "gel.audio.enable":
          nuxtApp.$store.commit("setAudioMuted", false);
          break;
        case "gel.audio.mute":
          nuxtApp.$store.commit("setAudioMuted", true);
          break;
        case "gel.gamePlay.stop":
          nuxtApp.$store.commit("disableGamePlay");
          break;
        case "gel.gamePlay.resume":
          nuxtApp.$store.commit("enableGamePlay");
          break;
      }
    } catch (err) {
      console.error(`Error occurred handling event: ${err}`);
    }
  });

  return async (eventName, data) => {
    // Extract any upstream wallet errors from bet results and forward to provider
    if (eventName != "app.placedBets" || !data.bet) return;

    console.debug(`onAppEvent: ${eventName}`);
    for (const bet of data.bet) {
      if (bet.upstream && bet.upstream.JavaScriptWalletMessage)
        dispatchWalletMessage(bet.upstream.JavaScriptWalletMessage);
    }
  };
});
