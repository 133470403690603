<template>
  <div class="nuxt-error">
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#DBE1EC" viewBox="0 0 48 48">
      <path
        d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"
      />
    </svg>

    <h1 v-if="error.statusCode === 404">{{ tTrim("error.404") }}</h1>
    <h1 v-else>{{ tTrim("error.error") }}</h1>

    <p>{{ tTrim(error.message) }}</p>

    <p v-if="hlgErrorCause">
      {{ tTrim(hlgErrorCause) }}
    </p>
  </div>
</template>

<!-- Layout doesn't seem to want to work for error pages, so just copying the relevant CSS from empty layout for now -->
<style lang="scss">
.nuxt-error {
  @apply flex items-center justify-center;
  @apply flex flex-col;

  text-align: center;

  min-height: 100vh;
  width: 100%;

  & > div {
    @apply flex-grow;
  }
}
</style>

<script>
import { HLGError } from "~~/utils/errors";
export default {
  name: "nuxt-error",
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const { tTrim } = useHLGLocalisation();
    return { tTrim };
  },

  beforeMount() {
    // Maintenance mode can occur prior to launch before store has a valid lang
    const lang = this.$store.state.i18n.language ? this.$store.state.i18n.language : this.$config.public.hlg.lang;

    const nuxtApp = useNuxtApp();
    nuxtApp.$vi18n.global.locale = lang;
  },

  computed: {
    hlgErrorCause() {
      if (this.error.cause instanceof HLGError) return this.error.cause.message;

      return null;
    },
  },
};
</script>
