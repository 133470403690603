export const state = () => ({
  limits: {
    unit: {
      min: 0,
      max: 0,
    },
    total: {
      min: 0,
      max: 0,
    },
    betslip: {
      min: 0,
      max: 0,
    },
    max_payout: 0,
    stake_multiple: 0,
  },
  loadedCurrency: null,
  loading: false,
});

export const mutations = {
  setLimits(state, { currency, limits }) {
    state.limits = limits;
    state.loadedCurrency = currency;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async updateLimits({ commit }, currency) {
    const { client } = useApolloClient();

    try {
      console.log(`Loading wager limits for currency ${currency} ...`);
      commit("setLoading", true);

      let {
        data: { wagerLimit: limits },
      } = await client.query({
        query: gql`
          query ($currency: String!) {
            wagerLimit(currency: $currency) {
              unit {
                min
                max
              }
              total {
                min
                max
              }
              betslip {
                min
                max
              }
              max_payout
              stake_multiple
            }
          }
        `,
        variables: {
          currency,
        },
        fetchPolicy: "no-cache",
      });

      // console.log(limits)

      commit("setLimits", { limits, currency });
    } catch (err) {
      console.warn(`Failed to update wager limits for ${currency}`);
      console.error(err);
    } finally {
      commit("setLoading", false);
    }
  },
};
