import { isSupportedLocale } from "~/utils/locale";

export const state = () => ({
  locale: null,
  languages: ["en"],
  language: null,
});

function checkLanguage(lang, state, checkDash = true) {
  try {
    lang = Intl.getCanonicalLocales(lang)[0];
    if (state.languages.indexOf(lang) !== -1) return lang;

    if (checkDash) return checkLanguage(lang.split("-")[0], state, false);
  } catch (err) {
    // ...
    console.error(err);
  }

  return null;
}

function defaultLanguage(state) {
  const config = useRuntimeConfig();

  let def = checkLanguage(config.public.hlg.lang, state);
  if (def == null) {
    console.warn("Unsupported default language ", config.public.hlg.lang);
  }
  return def || "en-GB";
}

export const mutations = {
  SET_LANGUAGE(state, lang) {
    let def = defaultLanguage(state);
    lang = checkLanguage(lang || def, state);
    if (lang == null) {
      console.warn(`Unsupported language ${lang}; falling back to ${def}`);
      lang = def;
    }
    state.language = lang;
    console.log(`Using language: ${state.language}`);
  },

  SET_LANGUAGES(state, languages) {
    state.languages = Intl.getCanonicalLocales(languages);
    console.log(`Available languages ${languages}`);
  },

  SET_LOCALE(state, locale) {
    const canonicalLocale = Intl.getCanonicalLocales(locale)[0];

    // Check for autonumeric options support
    if (!isSupportedLocale(canonicalLocale)) throw new Error(`Unsupported locale: ${canonicalLocale}`);

    state.locale = canonicalLocale;
    console.log(`Using locale: ${canonicalLocale}`);
  },
};

export const actions = {
  async changeLanguage({ commit, state }, language) {
    commit("SET_LANGUAGE", language);

    const nuxtApp = useNuxtApp();
    nuxtApp.$vi18n.global.locale = state.language;
  },
};
