import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/opt/build/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_vuex_svbYN5BfhA from "/opt/build/repo/plugins/1.vuex.ts";
import _10_feathericons_5v1BYkDO0Q from "/opt/build/repo/plugins/10.feathericons.ts";
import _2_i18n_EuExFkfPVU from "/opt/build/repo/plugins/2.i18n.ts";
import _20_provider_MBuJbTC2Vc from "/opt/build/repo/plugins/20.provider.ts";
import _3_socket_io_vnALLiBRw2 from "/opt/build/repo/plugins/3.socket-io.ts";
import _50_apollo_aevO3XLeXM from "/opt/build/repo/plugins/50.apollo.js";
import _99_nuxtClientInit_NUj0h2K2PO from "/opt/build/repo/plugins/99.nuxtClientInit.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  _1_vuex_svbYN5BfhA,
  _10_feathericons_5v1BYkDO0Q,
  _2_i18n_EuExFkfPVU,
  _20_provider_MBuJbTC2Vc,
  _3_socket_io_vnALLiBRw2,
  _50_apollo_aevO3XLeXM,
  _99_nuxtClientInit_NUj0h2K2PO
]