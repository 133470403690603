import type { NuxtApp } from "#app";

import type { ProviderPluginInit, ProviderEventHandler, AppEvent } from "~~/utils/providerPlugin";

import EveryMatrixPlugin from "~/providerPlugin/everymatrix";
import IForiumPlugin from "~/providerPlugin/iforium";
import PariplayPlugin from "~/providerPlugin/pariplay";
import OneXTwoPlugin from "~/providerPlugin/1x2";

const pluginMap: Record<string, ProviderPluginInit> = {
  everymatrix: EveryMatrixPlugin,
  iforium: IForiumPlugin,
  "iforium-novibet": IForiumPlugin,
  pariplay: PariplayPlugin,
  "1x2": OneXTwoPlugin,
};

export default defineNuxtPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig();

  const initFn: ProviderPluginInit | undefined = pluginMap[config.hlg_api_provider];
  const eventFn: ProviderEventHandler | undefined = initFn ? initFn(nuxtApp as NuxtApp) : undefined;

  async function onAppEvent(eventName: AppEvent, data: any): Promise<void> {
    if (eventFn == undefined) return;

    return eventFn(eventName, data);
  }

  return {
    provide: {
      providerEvent: onAppEvent,
    },
  };
});
