// If a translation key is missing, the key itself is returned as the translation string.
// The first matching prefix in this list will be removed from the key before returning.
// Note: "abbr." prefix is already removed by tTrim
const MissingKeyPrefixes: string[] = [
  "eventStatus.type.",
  "priceKind.price.",
  "priceKind.type.",
  "price.",
  "product.",
  "selection.",
  "team.",
  "wagerStatus.",
  "wagerType.",
  "multiplebet.", // hlg override
];

export interface HLGProduct {
  gameType: string | null;
  gameMode: string | null;
  region: string | null;
}

export interface SportCodes {
  sportCode?: string | null;
  sportSubcode?: number | null;
  countryCode?: number | null;
}

export interface IDPart {
  id: string;
  name: string;
}

export interface EventGroupData extends IDPart, SportCodes {
  homeTeamCode?: string;
  awayTeamCode?: string;
  hlgProduct: HLGProduct;
  teamScores?: Record<string, string | number>;
}

export interface EventData extends IDPart {}

export interface SelectionData extends IDPart {
  translationId?: string;
}

export type TranslateParams = Record<string, string>;

export interface TranslateOptions {
  abbr?: boolean; // Return abbreviated translation if available
  fallback?: string; // String to return in case of missing translation
  hlgProduct?: HLGProduct; // For prefix override via HLG product
  sportCodes?: SportCodes; // For prefix override via sub codes
}

export function useLocalisation() {
  const nuxtApp = useNuxtApp();
  const store = useStore();
  const config = useRuntimeConfig();

  // Null converted to undefined to allow browser fallback
  const i18nLocale = store.state.i18n?.locale ?? undefined;

  /**
   * Returns $t() translated string based on the translationKey with param
   * substitution. In case of a missing key, translationFallback will be returned
   * or the original translationKey minus any matching "MissingKeyPrefixes"
   * @param translationKey - key e.g 'ui.close'
   * @param translationParams - translation template subsitution eg {name: 'Alfred'}
   * @param options - options to apply to translation
   * @returns localised string
   */
  function tTrim(
    translationKey: string,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ): string {
    if (typeof options === "string" || options instanceof String)
      throw new Error("tTrim translation fallback string no longer supported. Change to object.");

    if (options.abbr) {
      // See if abbreviated version exists before direct key lookup. key used as fallback to allow missing key detection
      const transResult = tTrim(`abbr.${translationKey}`, translationParams, {
        ...options,
        abbr: false,
        fallback: translationKey,
      });
      if (transResult != translationKey) return transResult;
    }

    const transResult = nuxtApp.$vi18n.global.t(translationKey, translationParams);

    if (transResult != translationKey) return transResult;

    if (config.public.debugI18NBypassFallback) return translationKey;

    if (options.fallback !== undefined) return options.fallback;

    // return the key but attempt to trim prefixes not meant for display
    const key = translationKey.startsWith("abbr.") ? translationKey.slice("abbr.".length) : translationKey;
    for (const prefix of MissingKeyPrefixes) {
      if (key.startsWith(prefix)) return key.slice(prefix.length);
    }

    return transResult;
  }

  /** Returns true if a translation exists for the given key in ANY of the fallback
   * languages e.g de-DE -> de -> en-GB
   * @param key - key e.g 'ui.close'
   */
  function tExists(key: string) {
    // use key as fallback to avoid missing trim mangling the result.
    const result = tTrim(key, {}, { fallback: key });
    // workaround lack of exists method in vue-i18n that works across fallbacks
    return key !== result;
  }

  /**
   * Translates key using tTrim and attempting various sport code related
   * overrides.
   * @param translationKey - Key e.g 'ui.close'
   * @param translationParams - translation template substitution params
   * @param options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tTrimSportOverrides(translationKey: string, translationParams: TranslateParams, options: TranslateOptions) {
    const sportCodes = options?.sportCodes;
    const hlgProduct = options?.hlgProduct;

    const sportCodeKeys = [
      `${sportCodes?.sportCode}.${sportCodes?.countryCode}.${sportCodes?.sportSubcode}.${translationKey}`,
      `${sportCodes?.sportCode}.${sportCodes?.countryCode}.${translationKey}`,
      `${sportCodes?.sportCode}.${translationKey}`,
    ];
    const productCodeKeys = [
      `${hlgProduct?.gameType}-${hlgProduct?.region}-${hlgProduct?.gameMode}.${translationKey}`,
      `${hlgProduct?.gameType}-${hlgProduct?.region}.${translationKey}`,
      `${hlgProduct?.gameType}.${translationKey}`,
    ];

    const keys = [
      ...(hlgProduct !== undefined ? productCodeKeys : []),
      ...(sportCodes !== undefined ? sportCodeKeys : []),
      `${translationKey}`,
    ];

    for (const key of keys) {
      if (tExists(options.abbr ? `abbr.${key}` : key)) return tTrim(key, translationParams, options);
    }

    // Final attempt with fallback allowed
    return tTrim(translationKey, translationParams, options);
  }

  /**
   * Translate a team code to a full team name or abbr
   * @param teamCode - e.g "LEE"
   * @param eventGroup - sportCode/sportSubcode/countryCode for override support
   * @param translationParams - translation template substitution params
   * @param options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tTeamName(
    teamCode: string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return tTrimSportOverrides(`team.${teamCode}`, translationParams, {
      sportCodes: {
        sportCode: eventGroup?.sportCode,
        sportSubcode: eventGroup?.sportSubcode,
        countryCode: eventGroup?.countryCode,
      },
      ...options,
    });
  }

  /** Returns a translated version of "name" allowing for sport overrides,
   *  home/away team and fixture result substitutions.
   */
  function translateName(
    keyPath: string,
    name: string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams,
    options: TranslateOptions
  ) {
    const params: Record<string, string> = {};

    if (eventGroup?.homeTeamCode != undefined && eventGroup?.awayTeamCode != undefined) {
      const homeTeam = tTeamName(eventGroup.homeTeamCode, eventGroup, translationParams, options);
      const awayTeam = tTeamName(eventGroup.awayTeamCode, eventGroup, translationParams, options);

      params.home = homeTeam ? homeTeam : tTrim("ui.home");
      params.away = awayTeam ? awayTeam : tTrim("ui.away");
      params.visitor = awayTeam ? awayTeam : tTrim("ui.visitor");

      // This comes from BBGQL only for custhistory queries
      if (eventGroup?.teamScores !== undefined) {
        params.homeScore = `${eventGroup.teamScores[eventGroup.homeTeamCode]}`;
        params.awayScore = `${eventGroup.teamScores[eventGroup.awayTeamCode]}`;
        params.visitorScore = `${eventGroup.teamScores[eventGroup.awayTeamCode]}`;
      }
    }

    return tTrimSportOverrides(
      `${keyPath}${name}`,
      { ...params, ...translationParams },
      {
        fallback: name,
        sportCodes: {
          sportCode: eventGroup?.sportCode,
          sportSubcode: eventGroup?.sportSubcode,
          countryCode: eventGroup?.countryCode,
        },
        ...options,
      }
    );
  }

  // Only used by EPOS. Disabled until moved to shared library.
  /** Returns translation of a sport based on abetcode with a fallback
   *  to the fullName in case of a missing translation key.
   * @param {object} sport - Sport object
   * @param {string} sport.abetCode - Sport code
   * @param {string} sport.fullName - Default name of the sport
   * @param {object} sport.hlgProduct - Optional Product for HLG Virtuals
   */
  // function tSportName(sport) {
  //   if (sport.hlgProduct)
  //     return tTrim(`hlg.product.${sport.hlgProduct.productCode}`, {}, { fallback: sport.hlgProduct.productCode });
  //   return tTrim(`sport.${sport.abetCode}`, {}, { fallback: sport.fullName });
  // }

  /** Returns translation of the eventGroup name with optional overrides
   *  applied based on sportCode, sportSubCode and country.
   *  Eventgroup may represent an NFL division, Football fixture, Horse racing meet etc.
   *
   * @param eventGroup
   * @param translationParams - translation template subsitution eg {name: 'Alfred'}
   * @param options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tEventGroupName(
    eventGroup: EventGroupData,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return translateName("eventGroup.", eventGroup.name, eventGroup, translationParams, options);
  }

  /**
   * When eventGroup represents a "fixture", this returns a suitable
   * localised name in the A vs B or B @ A format depending on home/away teams
   * and sport. Result/scores will be included where available
   */
  function tFixtureName(
    eventGroup: EventGroupData,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    // Fixture based formatting not available without team codes
    if (eventGroup.homeTeamCode === undefined || eventGroup.awayTeamCode === undefined)
      return tEventGroupName(eventGroup, translationParams, options);

    const key = eventGroup.teamScores ? "fixture.result" : "fixture.pending";
    return translateName("", key, eventGroup, translationParams, options);
  }

  /** Returns translation of the event name
   *  Events are sport specific and may represent:-
   *    - a meet race time in horse racing
   *    - a betting market in other sports
   * @param {string|object} event - event name or object containing "name" field
   * @param {object} eventGroup - eventGroup required for sportCode override and team name/results support
   * @param {object} [translationParams={}] - translation template subsitution eg {name: 'Alfred'}
   * @param {object} options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tEventName(
    event: EventData | string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    const key = typeof event === "string" ? event : event.name;

    return translateName("event.", key, eventGroup, translationParams, options);
  }

  /** Returns translation of the selection name. Where a selection includes
   *  a translationId field, this will be used instead of the name as a key.
   * @param selection - selection name or object
   * @param eventGroup - eventGroup required for sportCode override and team name/results support
   * @param translationParams - translation template subsitution eg {name: 'Alfred'}
   * @param options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tSelectionName(
    selection: SelectionData | string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    const key = typeof selection === "string" ? selection : selection.translationId ?? selection.name;

    // For safety, numbers are not translated (unless translationId available) as areas of the app are
    // unable to distinguish between "1" and "1" where the first is literally the number 1 and the
    // second means home team to win (from 1x2 market).
    if (Number.isNaN(+key)) return translateName("selection.", key, eventGroup, translationParams, options);

    return key;
  }

  /** Returns translated wager name i.e "Singles"
   * @param wagerName - string to translate or object with wager.name field
   * @param translationParams - translation template subsitution eg {name: 'Alfred'}
   * @param options - options to apply to translation e.g abbr: true for abbreviated results
   */
  function tWagerName(wagerName: string, translationParams: TranslateParams = {}, options: TranslateOptions = {}) {
    return tTrim(`wagerType.${wagerName}`, translationParams, options);
  }

  /** Returns an optionally abbreviated translation of the priceKind text
   * @param priceKindText - e.g "Early", "Board"
   * @param abbr - Return abbreviated form if true
   * @param {('type'|'price')} variant - type or price variant. Result may include additional text
   *                                     e.g "Early Price" when variant is 'price' rather than "Early".
   * @returns {string} translated text
   */
  function tPriceKindText(priceKindText: string, abbr: boolean = false, variant: "type" | "price" = "type") {
    const fallbackTextAbbr = variant === "type" ? priceKindText.substring(0, 1) : `${priceKindText.substring(0, 1)}P`;
    const fallbackText = variant === "type" ? priceKindText : `${priceKindText} Price`;

    return tTrim(
      `priceKind.${variant}.${priceKindText}`,
      {},
      { abbr: abbr, fallback: abbr ? fallbackTextAbbr : fallbackText }
    );
  }

  /** Returns an optionally abbreviated translation of the priceText
   * @param priceText - e.g "SP", "EVS"
   * @param abbr - Return abbreviated form if true
   * @returns translated price text
   */
  function tPriceText(priceText: string, abbr: boolean = false) {
    return tTrim(`price.${priceText}`, {}, { abbr: abbr }).toLocaleUpperCase(i18nLocale);
  }

  /** Returns an optionally abbreviated translation of the eventStatus text
   * @param eventStatusText - event status
   * @param abbr - Return abbreviated form if true
   * @returns translated status text
   */
  function tEventStatusText(eventStatusText: string, abbr: boolean = false) {
    return tTrim(`eventStatus.type.${eventStatusText}`, {}, { abbr: abbr, fallback: eventStatusText.substring(0, 1) });
  }

  function tBettyErrorCode(bettyErrorCode: string, translationParams: TranslateParams = {}) {
    return tTrim(`error.bettyCode.${bettyErrorCode}`, translationParams, { fallback: bettyErrorCode });
  }

  /** Convenience wrapper around localeString to include app defaults
   * @param intlOptions - additional formatting options
   * @param locale - Store locale will be used if null/undefined
   * @returns Intl localised/formatted date
   */
  function tLocaleString(date: Date, intlOptions: Intl.DateTimeFormatOptions = {}, locale?: string) {
    // do not default day/month/year format here unless you check whether
    // caller wants just day/month components and not year.
    const options: Intl.DateTimeFormatOptions = {
      dateStyle: "medium",
      timeStyle: "medium",
      ...intlOptions,
    };

    return date.toLocaleString(locale ?? i18nLocale, options);
  }

  /** Convenience wrapper around localeTimeString to include app defaults
   * @param intlOptions - additional formatting options
   * @param locale - Store locale will be used if null.
   * @returns Intl localised/formatted date
   */
  function tLocaleTimeString(date: Date, intlOptions: Intl.DateTimeFormatOptions = {}, locale?: string) {
    const options: Intl.DateTimeFormatOptions = {
      timeStyle: "medium",
      ...intlOptions,
    };

    return date.toLocaleString(locale ?? i18nLocale, options);
  }

  return {
    tBettyErrorCode,
    tEventGroupName,
    tEventName,
    tEventStatusText,
    tExists,
    tFixtureName,
    tPriceKindText,
    tPriceText,
    tSelectionName,
    // tSportName,
    tTeamName,
    tTrim,
    tTrimSportOverrides,
    tWagerName,
    tLocaleString,
    tLocaleTimeString,
  };
}
