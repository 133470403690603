import shortid from "shortid";

import { isInvalidPrice } from "~/utils/price";
import { isMaintenanceMode, MaintenanceError } from "~/utils/maintenance";

const Bet = function () {
  this.id = shortid.generate();
  this.items = [];
  this.wagers = [];
  this.multiples = [];
  this.tax = {
    stakeMultiple: 1,
    paymentMultiple: 1,
    taxRate: 0,
  };
  this.active = true;
};

export const state = () => ({
  bets: [new Bet()],
  working: false,
  bettyErrorCode: 0,
  activeKeypad: null,
});

export const mutations = {
  setActiveKeypad(state, keypadId) {
    state.activeKeypad = keypadId;
  },

  newBet(state) {
    state.bets.push(new Bet());
  },

  addBetItem(state, item) {
    let index = state.bets.findIndex((bet) => {
      return bet.active;
    });
    state.bets[index].items.push(item);
  },

  removeBetItem(state, { betId, item }) {
    let index = state.bets.findIndex((bet) => {
      return bet.id == betId;
    });
    let itemIndex = state.bets[index].items.findIndex((i) => {
      return i.id == item.id;
    });

    // Remove of non-existant item, silently ignored
    if (itemIndex === -1) return;

    state.bets[index].items.splice(itemIndex, 1);
  },

  updateBetItem(state, { betId, item }) {
    let index = state.bets.findIndex((bet) => {
      return bet.id == betId;
    });
    let itemIndex = state.bets[index].items.findIndex((i) => {
      return i.id == item.id;
    });
    state.bets[index].items.splice(itemIndex, 1, item);
  },

  updateBetMultiples(state, { betId, multiples }) {
    let index = state.bets.findIndex((bet) => {
      return bet.id == betId;
    });

    let bet = state.bets[index];
    // We no longer filter out singles on the server so that the tax information makes it through
    bet.multiples = multiples.filter((betType) => {
      return betType.name != "Singles";
    });

    bet.tax.stakeMultiple = multiples[0]?.stakeMultiple || 1;
    bet.tax.paymentMultiple = multiples[0]?.paymentMultiple || 1;
    bet.tax.taxRate = multiples[0]?.taxRate || 0;
  },

  clearBetItems(state, id) {
    let index = state.bets.findIndex((bet) => {
      return bet.id == id;
    });
    state.bets[index].items = [];
  },

  clearBetWagers(state, id) {
    let index = state.bets.findIndex((bet) => {
      return bet.id == id;
    });
    state.bets[index].wagers = [];
  },

  storeBetWager(state, { betId, wager }) {
    const b = state.bets.findIndex((b) => {
      return b.id == betId;
    });
    const w = state.bets[b].wagers.findIndex((w) => {
      return w.id == wager.id;
    });

    if (wager.stake > 0) return state.bets[b].wagers.splice(w >= 0 ? w : state.bets[b].wagers.length, 1, wager);
  },

  removeBetWager(state, { betId, wagerId }) {
    const b = state.bets.findIndex((b) => {
      return b.id == betId;
    });
    const w = state.bets[b].wagers.findIndex((w) => {
      return w.id == wagerId;
    });

    // Remove of non-existant item, silently ignored
    if (w === -1) return;

    state.bets[b].wagers.splice(w, 1);
  },

  setBettyErrorCode(state, errCode) {
    state.bettyErrorCode = errCode;
  },
};

export const actions = {
  addItem({ commit, rootState }, item) {
    if (!rootState.account.custRef) {
      commit("setLoginRequired", true, { root: true });

      return;
    }

    // Extra sanity check - UI should already prevent user attempting this
    if (isInvalidPrice(item.price)) {
      console.error("Unable to add item with invalid odds to betslip.");
      return;
    }

    item = Object.assign({}, item, { id: shortid.generate(), event: null, selection: null, fav: false });
    commit("addBetItem", item);
  },

  removeItem({ commit, state }, item) {
    // Remove selection from active bet only
    let betIdx = state.bets.findIndex((bet) => {
      return bet.active;
    });
    let itemIdx = state.bets[betIdx].items.findIndex((i) => {
      return i.selectionId == item.selectionId;
    });

    commit("removeBetItem", { betId: state.bets[betIdx].id, item: state.bets[betIdx].items[itemIdx] });
  },

  clearBet({ commit, rootState }, id) {
    commit("setBettyErrorCode", 0);
    commit("clearBetItems", id);
    commit("clearBetWagers", id);
    commit("updateBetMultiples", { betId: id, multiples: [] });
  },

  async placeBet({ commit, state, rootState }, betId) {
    const { client } = useApolloClient();
    const providerEvent = useProviderEvent();

    const activeBetsStore = useActiveBetsStore();

    if (!rootState.account.custRef) throw "No custRef set";

    if (rootState.gamePlayDisabled) throw "Unable to place bets. Game play disabled.";

    if (await isMaintenanceMode()) throw new MaintenanceError();

    const bet = state.bets.find((bet) => bet.id === betId);
    if (bet === undefined) return false;

    const bets = [
      {
        id: bet.id,
        wagers: bet.wagers,
      },
    ];

    try {
      let { data: result } = await client.mutate({
        mutation: gql`
          mutation ($bets: [Object], $account: String, $simple: Boolean) {
            bet(bets: $bets, account: $account, simple: $simple)
          }
        `,
        variables: {
          bets,
          account: rootState.account.custRef,
          simple: true,
        },
        fetchPolicy: "no-cache",
      });

      // Provider event first so plugin can check set length
      providerEvent("app.placedBets", result);
      activeBetsStore.markActive(result.bet.filter((v) => v.placed).map((v) => v.wagerNo));

      // Update balance
      const stake = result.bet.reduce((a, c) => {
        return a + (c.placed ? c.stake : 0);
      }, 0);
      commit("account/updateAccount", { balance: rootState.account.balance - stake }, { root: true });

      return result.bet;
    } catch (err) {
      console.log(err);
      throw "Error placing bets";
    }
  },

  async getSelection({ commit }, id) {
    const { client } = useApolloClient();

    try {
      let {
        data: { selection },
      } = await client.query({
        query: gql`
          query ($id: String!) {
            selection(id: $id) {
              id
              index
              name
              price
              statusText
              translationId
              event {
                id
                placeRules
                scheduledStartAt
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
      });

      return selection;
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async getMultiples({ commit }, items) {
    const { client } = useApolloClient();

    try {
      let {
        data: { betTypes },
      } = await client.query({
        query: gql`
          query ($selections: [Object]!, $filterSingles: Boolean) {
            betTypes(selections: $selections, filterSingles: $filterSingles)
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
          selections: items,
          filterSingles: false,
        },
      });

      // If we filter multiples at the server level as before, we won't get tax info without valid multiples

      // Reset betty error code
      commit("setBettyErrorCode", 0);

      return betTypes;
    } catch (err) {
      const gqlError = err.graphQLErrors?.[0];
      if (gqlError?.extensions?.code == "BETTY_ERROR") {
        const bettyErrorCode = gqlError.extensions.bettyErrorCode;

        const msg = `Failed to fetch wager types; betty error code = ${bettyErrorCode}`;
        commit("setBettyErrorCode", bettyErrorCode);
      }

      console.log(err);
      return [];
    }
  },
};
