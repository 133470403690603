import { CurrencyHackData } from "./currencyHack";

export function useNumberFormatHack(provider) {
  class NumberFormatHack extends Intl.NumberFormat {
    constructor(locales, options) {
      if (options.currency === undefined) return super(locales, options);
      let thingy = CurrencyHackData[provider] || CurrencyHackData.default;
      options.currency = options.currency.toUpperCase();
      let hack = thingy[options.currency] || CurrencyHackData.default[options.currency];
      if (options.style != "currency" || hack == undefined) return super(locales, options);

      // Modify currency symbol
      let fmt = super(locales, { ...options, currency: hack.currency });
      return {
        format: function (val) {
          return fmt.format(val).replace(hack.symbolRegexp, hack.symbol);
        },
      };
    }
  }

  Intl.NumberFormat = NumberFormatHack;
}
