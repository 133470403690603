import { defineStore } from "pinia";

// The main purpose of this store is to track when there are active bets for this session.
// When a bet is placed, its wager number is added to the activeBets set and when it's
// settled it's removed.
//
// This is used by the 1x2 plugin to send play-start and play-end events when the first bet
// is placed and last one is settled respectively.

export const useActiveBetsStore = defineStore("activebets", () => {
  const activeBets = ref<Set<string>>(new Set());

  function markActive(bets: string[]) {
    for (const betID of bets) {
      activeBets.value.add(betID);
    }
  }

  function markInactive(bets: string[]) {
    for (const betID of bets) {
      activeBets.value.delete(betID);
    }
  }

  return {
    activeBets,
    markActive,
    markInactive,
  };
});
