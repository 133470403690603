import type { SettleBetsData, PlacedBetsData } from "~~/utils/providerPlugin";

declare global {
  interface Window {
    // Annoyingly, there's no types for clientAPI
    clientAPI: any;
  }
}

type BalanceUpdate = "place" | "settle";

export default defineProviderPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig();
  const router = useRouter();

  const activeBetsStore = useActiveBetsStore();

  nuxtApp.$store.watch((state) => state.provider?.launchComplete, onLaunchComplete);

  async function onLaunchComplete() {
    // Init API
    console.log("[1X2] Launch complete; initialising integration");
    if (window.clientAPI == null) {
      console.warn(`[1X2] 1x2 client API not loaded, unable to initialise integration`);
      return;
    }

    const params = new URLSearchParams(document.location.search);
    const launchParams = {
      ...Object.fromEntries(params.entries()),
      environment: config.configuration === "production" ? "production" : "staging",
    };
    if (!(await window.clientAPI.init(launchParams))) {
      console.error(`[1X2] Client initialisation failed`);
      return;
    }

    // Configure API
    const upstream = nuxtApp.$store.state.provider?.upstream;
    if (upstream == null) {
      console.error("[1X2] Client initialisation failed: no upstream data in launch result");
      return;
    }
    ``;

    await window.clientAPI.configure({
      accountID: upstream.accountID,
      sessionID: upstream.sessionID,
      customerID: upstream.customerID,
      username: upstream.operatorID,
      currencyCode: upstream.currency,
      jurisdiction: upstream.jurisdiction,
      freeroundsAvailable: upstream.freerounds,
      aams: upstream.aams,
    });

    // Start of game load
    window.clientAPI.send("load-start");
    window.clientAPI.send("load-progress", 100);
    window.clientAPI.send("load-end");

    // Setup message handlers
    nuxtApp.$store.watch((state) => state.audioMuted, notifyAudioMuted);
    window.clientAPI.callbacks["audio-enable"].add(() => nuxtApp.$store.commit("setAudioMuted", false));
    window.clientAPI.callbacks["audio-disable"].add(() => nuxtApp.$store.commit("setAudioMuted", true));

    window.clientAPI.callbacks["update-balance"].add((value: number) =>
      nuxtApp.$store.commit("account/updateAccount", { balance: value })
    );

    window.clientAPI.callbacks["sync-balance"].add(async () => {
      await nuxtApp.$store.dispatch("provider/refreshBalance");
      notifyBalance(nuxtApp.$store.state.account?.balance ?? 0, "place");
    });

    window.clientAPI.callbacks["game-pause"].add(() => nuxtApp.$store.commit("disableGamePlay"));
    window.clientAPI.callbacks["game-resume"].add(() => nuxtApp.$store.commit("enableGamePlay"));

    // FIXME: useRules() can't be called here due to issues with accessing the store. This will require moving the store to pinia
    // if (!config.hide_rules) {
    //   window.clientAPI.callbacks["help-open"].add(() => {
    //     const { rulesPath } = useRules(false);
    //     if (rulesPath === undefined) return;

    //     router.push(rulesPath);
    //   });
    // }

    // Initial stake and balance
    window.clientAPI.send("value-stake", 0);
    window.clientAPI.send("value-win", 0);
    notifyBalance(nuxtApp.$store.state.account?.balance ?? 0, "place");

    console.log("[1X2] Client configuration complete");
  }

  function notifyAudioMuted(muted: boolean) {
    if (window.clientAPI == null) return;
    window.clientAPI.send(`audio-${muted ? "disable" : "enable"}`);
  }

  function notifyBalance(balance: number, updateType: BalanceUpdate, stake: number = 0) {
    if (window.clientAPI == null) return;
    ``;
    window.clientAPI.send("value-balance", {
      balance,
      mode: updateType,
      stake,
    });
  }

  watch(
    () => activeBetsStore.activeBets.size,
    (newSize, oldSize) => {
      if (window.clientAPI == null) return;

      // console.log(`Active bets changed: new size = ${newSize} old = ${oldSize}`);
      if (oldSize == 0 && newSize > 0) {
        // Placing bets, start of round
        console.log(`[1X2] Starting round`);
        window.clientAPI.send("play-start");
      } else if (newSize == 0 && oldSize > 0) {
        // All bets settled, end of round
        console.log(`[1X2] Ending round`);
        window.clientAPI.send("play-end");
      }
    }
  );

  return async (eventName, data) => {
    console.debug(`onAppEvent: ${eventName}`);
    // console.log(data);

    switch (eventName) {
      case "app.placedBets":
        const placedData = data as PlacedBetsData;

        // Update balance using last placed bet
        const lastBet = placedData.bet.findLast((v) => v.placed);
        if (lastBet && typeof lastBet.upstream?.balance === "number") {
          notifyBalance(
            lastBet.upstream.balance,
            "place",
            placedData.bet.reduce<number>((acc, cur) => acc + (cur.placed ? cur.stake : 0), 0)
          );
        }
        break;

      case "app.settleBets":
        const settleData = data as SettleBetsData;

        // Update total win value
        const totalWin = settleData.reduce<number>((acc, cur) => acc + cur.amount, 0);
        window.clientAPI.send("value-win", totalWin);

        // Update balance using last settled bet
        const lastSettled = settleData.at(-1);
        if (lastSettled && typeof lastSettled.upstreamResponse?.balance === "number") {
          notifyBalance(lastSettled.upstreamResponse.balance, "settle");
        }
        break;

      case "slip.changePage":
        const page = data as string;
        const isHistory = page === "bethistory";
        window.clientAPI.send(`history-${isHistory ? "open" : "close"}`);
        break;

      case "slip.totalStakeChanged":
        const amount = data as number;
        window.clientAPI.send("value-stake", amount);
        break;
    }
  };
});
