import type { SettleBetsData, PlacedBetsData } from "~~/utils/providerPlugin";

type PariplayTxMessage =
  | "onAppFrameReady"
  | "cashier"
  | "quit"
  | "gameDataLoaded"
  | "gameReady"
  | "roundStart"
  | "roundStarted"
  | "balance"
  | "ticketReceived"
  | "roundEnded";
type PariplayRxMessage = "stopAutobet" | "pause" | "resume";

export default defineProviderPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig();

  function dispatchPariplayMessage(message: PariplayTxMessage, data?: unknown) {
    const obj = {
      sender: nuxtApp.$store.state.provider?.product?.productCode,
      lang: nuxtApp.$store.state.i18n?.language,
      type: message,
      data,
    };
    console.debug(`Sending message ${message}: ${JSON.stringify(obj)}`);
    window.parent.postMessage(obj, "*");
  }

  // Event Handlers
  nuxtApp.$store.watch((state) => state.provider?.launchComplete, onLaunchComplete);
  function onLaunchComplete() {
    dispatchPariplayMessage("onAppFrameReady");
    dispatchPariplayMessage("gameDataLoaded", {
      LoadGameData: {
        playerTokenId: nuxtApp.$store.state.provider?.playerID,
        currencyCode: nuxtApp.$store.state.currency.code,
      },
    });
    dispatchPariplayMessage("gameReady");
  }

  window.addEventListener("message", (event) => {
    if (event.data?.type == undefined) return;

    console.debug(`Got message: ${event.data.type}`);
    // console.debug(event.data);
    switch (event.data.type as PariplayRxMessage) {
      case "stopAutobet":
        break;

      case "pause":
        nuxtApp.$store.commit("disableGamePlay");
        break;

      case "resume":
        nuxtApp.$store.commit("enableGamePlay");
        break;
    }
  });

  return async (eventName, data) => {
    console.debug(`onAppEvent: ${eventName}`);
    // console.log(data);

    switch (eventName) {
      case "app.placedBets":
        const placedData = data as PlacedBetsData;
        for (const bet of placedData.bet) {
          if (!bet.placed) continue;

          dispatchPariplayMessage("roundStart", {
            roundID: `${bet.wagerNo}_ROUND`,
            totalBet: bet.stake,
          });

          dispatchPariplayMessage("roundStarted", {
            roundID: `${bet.wagerNo}_ROUND`,
          });
        }
        break;

      case "app.settleBets":
        const settleData = data as SettleBetsData;
        for (const bet of settleData) {
          dispatchPariplayMessage("ticketReceived", {
            roundID: `${bet.wagerNumber}_ROUND`,
          });

          dispatchPariplayMessage("roundEnded", {
            roundID: `${bet.wagerNumber}_ROUND`,
            winAmount: bet.amount,
          });
        }

        break;
    }
  };
});
