// Wrapper around useLocalisation to fill in product based on store when not
// already passed in via caller. Intention is to minimise changes to useLocalisation
// with a view to moving that into a shared package for iFrame/EPOS usage.
import type {
  TranslateOptions,
  TranslateParams,
  EventGroupData,
  EventData,
  SelectionData,
} from "~/composables/localisation";

export function useHLGLocalisation() {
  const localisation = useLocalisation();
  const store = useStore();

  function mergeHLGProduct(options: TranslateOptions) {
    const product = options.hlgProduct;

    const gameType = product ? product.gameType : store.state.provider?.product?.gameType;
    const gameMode = product ? product.gameMode : store.state.provider?.product?.gameMode;
    const region = product ? product.region : store.state.provider?.product?.region;
    if (gameMode == null || region == null || gameType == null) throw new Error("Missing product info");

    return {
      ...options,
      hlgProduct: {
        gameType,
        gameMode,
        region,
      },
    };
  }

  function tTrimSportOverrides(translationKey: string, translationParams: TranslateParams, options: TranslateOptions) {
    // Patch product into options for iFrame
    return localisation.tTrimSportOverrides(translationKey, translationParams, mergeHLGProduct(options));
  }

  function tTeamName(
    teamCode: string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return localisation.tTeamName(
      teamCode,
      eventGroup,
      translationParams,
      mergeHLGProduct({ hlgProduct: eventGroup?.hlgProduct, ...options })
    );
  }

  function tEventGroupName(
    eventGroup: EventGroupData,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return localisation.tEventGroupName(
      eventGroup,
      translationParams,
      mergeHLGProduct({ hlgProduct: eventGroup?.hlgProduct, ...options })
    );
  }

  function tFixtureName(
    eventGroup: EventGroupData,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return localisation.tFixtureName(
      eventGroup,
      translationParams,
      mergeHLGProduct({ hlgProduct: eventGroup?.hlgProduct, ...options })
    );
  }

  function tEventName(
    event: EventData | string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return localisation.tEventName(
      event,
      eventGroup,
      translationParams,
      mergeHLGProduct({ hlgProduct: eventGroup?.hlgProduct, ...options })
    );
  }

  function tSelectionName(
    selection: SelectionData | string,
    eventGroup: EventGroupData | undefined,
    translationParams: TranslateParams = {},
    options: TranslateOptions = {}
  ) {
    return localisation.tSelectionName(
      selection,
      eventGroup,
      translationParams,
      mergeHLGProduct({ hlgProduct: eventGroup?.hlgProduct, ...options })
    );
  }

  function tWagerName(wagerName: string, translationParams: TranslateParams = {}, options: TranslateOptions = {}) {
    return localisation.tWagerName(wagerName, translationParams, mergeHLGProduct(options));
  }

  // Translation methods that do not support product/sport overrides are left unwrapped
  return {
    tBettyErrorCode: localisation.tBettyErrorCode,
    tEventGroupName,
    tEventName,
    tEventStatusText: localisation.tEventStatusText,
    tExists: localisation.tExists,
    tFixtureName,
    tPriceKindText: localisation.tPriceKindText,
    tPriceText: localisation.tPriceText,
    tSelectionName,
    tTeamName,
    tTrim: localisation.tTrim,
    tTrimSportOverrides,
    tWagerName,
    tLocaleString: localisation.tLocaleString,
    tLocaleTimeString: localisation.tLocaleTimeString,
  };
}
