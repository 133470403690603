import moment from "moment-timezone";

export const state = () => ({
  history: null,
  loading: false,
});

export const mutations = {
  setHistory(state, history) {
    state.history = history;
    state.loading = false;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async loadHistory({ commit, rootState }, { custRef, days }) {
    if (!custRef && !rootState.account.custRef) return;

    const { client } = useApolloClient();
    const { public: config } = useRuntimeConfig();

    commit("setLoading", true);

    try {
      // Fetching "days" worth of historical data relative to local timezone.
      const startDate = moment().startOf("day").subtract(days, "days");
      const endDate = moment().add(1, "day").startOf("day");

      console.log(`Customer history date range [${startDate}, ${endDate})`);
      const {
        data: { r },
      } = await client.query({
        query: gql`
          query ($custRef: String!, $start: String!, $end: String!, $includeResults: Boolean) {
            r: customer_history(custRef: $custRef, start: $start, end: $end, includeResults: $includeResults)
          }
        `,
        variables: {
          custRef: custRef || rootState.account.custRef,
          start: startDate,
          end: endDate,
          includeResults: !config.bet_history.exclude_results,
        },
        fetchPolicy: "no-cache",
      });

      commit("setHistory", r);
    } catch (err) {
      console.log(err);
    }
  },
};
