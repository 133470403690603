export async function isMaintenanceMode(): Promise<boolean> {
  const { public: config } = useRuntimeConfig();
  try {
    const res = await fetch(`${config.bgql}/maintenance/status.json`);
    const json = await res.json();
    return !!json.enabled;
  } catch (err) {
    if (err instanceof Error) console.error(`Error getting maintenance status: ${err.message}`);

    // A failure to get maintenance status should be treated as being in maintenance mode.
    // This ensures that maintenance mode shows while the server is rebooting.
    return true;
  }

  return false;
}

export class MaintenanceError extends Error {
  constructor() {
    super("error.maintenanceEnabled");
  }
}
