import type { Price } from "~~/composables/gamedata";

export function isInvalidPrice(price?: Price) {
  // NOTE: empty price is only treated as invalid for HLG games. For EPOS which
  //       supports additional events, this would be valid and signify a "SP".
  //       iFrame does not need to account for this though.
  if (price == undefined || price.numerator === undefined || price.denominator === undefined) return true;

  return price.denominator == 1 && price.numerator == 0;
}

// Round price to 2dp
export function RoundPrice(price: number) {
  return Math.round((price + Number.EPSILON) * 100) / 100;
}
