import { default as _91group_9346a7hEyrUOMeta } from "/opt/build/repo/pages/game/league/[group].vue?macro=true";
import { default as az5raKEh53ukMeta } from "~/rules/generic/sb-league/az.vue?macro=true";
import { default as elUW10h7q9TzMeta } from "~/rules/generic/sb-league/el.vue?macro=true";
import { default as en_45GBwF5WKAxhwuMeta } from "~/rules/generic/sb-league/en-GB.vue?macro=true";
import { default as esu4GODvdXfDMeta } from "~/rules/generic/sb-league/es.vue?macro=true";
import { default as hr26J12guke7Meta } from "~/rules/generic/sb-league/hr.vue?macro=true";
import { default as itlNLpUW9mnXMeta } from "~/rules/generic/sb-league/it.vue?macro=true";
import { default as ro_45ROIu7Qb7cQVIMeta } from "~/rules/generic/sb-league/ro-RO.vue?macro=true";
import { default as ru3CTd4HB8hXMeta } from "~/rules/generic/sb-league/ru.vue?macro=true";
import { default as trRUTbW9uiWwMeta } from "~/rules/generic/sb-league/tr.vue?macro=true";
import { default as rulesBRkY854yiMMeta } from "/opt/build/repo/pages/game/league/rules.vue?macro=true";
import { default as leagueq0UtZ6esqoMeta } from "/opt/build/repo/pages/game/league.vue?macro=true";
import { default as en_45GBNAbRHXLQRCMeta } from "~/rules/generic/iihf-single/en-GB.vue?macro=true";
import { default as esqJ1wqXaTyYMeta } from "~/rules/generic/iihf-single/es.vue?macro=true";
import { default as itqvc2rZnZSPMeta } from "~/rules/generic/iihf-single/it.vue?macro=true";
import { default as ruVDe7UAW0TZMeta } from "~/rules/generic/iihf-single/ru.vue?macro=true";
import { default as trk6DezHnNJvMeta } from "~/rules/generic/iihf-single/tr.vue?macro=true";
import { default as eldUxIPReC2MMeta } from "~/rules/generic/last60-single/el.vue?macro=true";
import { default as en_45GBAs4hZPFjbEMeta } from "~/rules/generic/last60-single/en-GB.vue?macro=true";
import { default as esJYGKcVuJEfMeta } from "~/rules/generic/last60-single/es.vue?macro=true";
import { default as hr3D2RtLpDHgMeta } from "~/rules/generic/last60-single/hr.vue?macro=true";
import { default as ro_45ROpbzM1JLW4hMeta } from "~/rules/generic/last60-single/ro-RO.vue?macro=true";
import { default as elwsoD03zE8rMeta } from "~/rules/generic/sb-single/el.vue?macro=true";
import { default as en_45GB3WR6YA5xGoMeta } from "~/rules/generic/sb-single/en-GB.vue?macro=true";
import { default as esKD8StcsnbXMeta } from "~/rules/generic/sb-single/es.vue?macro=true";
import { default as hrSQiymjp4HmMeta } from "~/rules/generic/sb-single/hr.vue?macro=true";
import { default as itY87YSD71H0Meta } from "~/rules/generic/sb-single/it.vue?macro=true";
import { default as ro_45ROPnJmq1TLXwMeta } from "~/rules/generic/sb-single/ro-RO.vue?macro=true";
import { default as ru3Sp0Lw3zucMeta } from "~/rules/generic/sb-single/ru.vue?macro=true";
import { default as trFa2QeQkkrzMeta } from "~/rules/generic/sb-single/tr.vue?macro=true";
import { default as rulesuavGUTDB65Meta } from "/opt/build/repo/pages/game/single/rules.vue?macro=true";
import { default as singletkrLh71bOVMeta } from "/opt/build/repo/pages/game/single.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
export default [
  {
    name: leagueq0UtZ6esqoMeta?.name ?? "game-league",
    path: leagueq0UtZ6esqoMeta?.path ?? "/game/league",
    children: [
  {
    name: _91group_9346a7hEyrUOMeta?.name ?? "game-league-group",
    path: _91group_9346a7hEyrUOMeta?.path ?? ":group()",
    meta: _91group_9346a7hEyrUOMeta || {},
    alias: _91group_9346a7hEyrUOMeta?.alias || [],
    redirect: _91group_9346a7hEyrUOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/game/league/[group].vue").then(m => m.default || m)
  },
  {
    name: rulesBRkY854yiMMeta?.name ?? "game-league-rules",
    path: rulesBRkY854yiMMeta?.path ?? "rules",
    children: [
  {
    name: az5raKEh53ukMeta?.name ?? "game-league-rules-sb-league-az",
    path: az5raKEh53ukMeta?.path ?? "sb-league-az",
    meta: az5raKEh53ukMeta || {},
    alias: az5raKEh53ukMeta?.alias || [],
    redirect: az5raKEh53ukMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/az.vue").then(m => m.default || m)
  },
  {
    name: elUW10h7q9TzMeta?.name ?? "game-league-rules-sb-league-el",
    path: elUW10h7q9TzMeta?.path ?? "sb-league-el",
    meta: elUW10h7q9TzMeta || {},
    alias: elUW10h7q9TzMeta?.alias || [],
    redirect: elUW10h7q9TzMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/el.vue").then(m => m.default || m)
  },
  {
    name: en_45GBwF5WKAxhwuMeta?.name ?? "game-league-rules-sb-league-en-GB",
    path: en_45GBwF5WKAxhwuMeta?.path ?? "sb-league-en-GB",
    meta: en_45GBwF5WKAxhwuMeta || {},
    alias: en_45GBwF5WKAxhwuMeta?.alias || [],
    redirect: en_45GBwF5WKAxhwuMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/en-GB.vue").then(m => m.default || m)
  },
  {
    name: en_45GBwF5WKAxhwuMeta?.name ?? "game-league-rules-sb-league-en",
    path: en_45GBwF5WKAxhwuMeta?.path ?? "sb-league-en",
    meta: en_45GBwF5WKAxhwuMeta || {},
    alias: en_45GBwF5WKAxhwuMeta?.alias || [],
    redirect: en_45GBwF5WKAxhwuMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/en-GB.vue").then(m => m.default || m)
  },
  {
    name: esu4GODvdXfDMeta?.name ?? "game-league-rules-sb-league-es",
    path: esu4GODvdXfDMeta?.path ?? "sb-league-es",
    meta: esu4GODvdXfDMeta || {},
    alias: esu4GODvdXfDMeta?.alias || [],
    redirect: esu4GODvdXfDMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/es.vue").then(m => m.default || m)
  },
  {
    name: hr26J12guke7Meta?.name ?? "game-league-rules-sb-league-hr",
    path: hr26J12guke7Meta?.path ?? "sb-league-hr",
    meta: hr26J12guke7Meta || {},
    alias: hr26J12guke7Meta?.alias || [],
    redirect: hr26J12guke7Meta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/hr.vue").then(m => m.default || m)
  },
  {
    name: itlNLpUW9mnXMeta?.name ?? "game-league-rules-sb-league-it",
    path: itlNLpUW9mnXMeta?.path ?? "sb-league-it",
    meta: itlNLpUW9mnXMeta || {},
    alias: itlNLpUW9mnXMeta?.alias || [],
    redirect: itlNLpUW9mnXMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/it.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROIu7Qb7cQVIMeta?.name ?? "game-league-rules-sb-league-ro-RO",
    path: ro_45ROIu7Qb7cQVIMeta?.path ?? "sb-league-ro-RO",
    meta: ro_45ROIu7Qb7cQVIMeta || {},
    alias: ro_45ROIu7Qb7cQVIMeta?.alias || [],
    redirect: ro_45ROIu7Qb7cQVIMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROIu7Qb7cQVIMeta?.name ?? "game-league-rules-sb-league-ro",
    path: ro_45ROIu7Qb7cQVIMeta?.path ?? "sb-league-ro",
    meta: ro_45ROIu7Qb7cQVIMeta || {},
    alias: ro_45ROIu7Qb7cQVIMeta?.alias || [],
    redirect: ro_45ROIu7Qb7cQVIMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: ru3CTd4HB8hXMeta?.name ?? "game-league-rules-sb-league-ru",
    path: ru3CTd4HB8hXMeta?.path ?? "sb-league-ru",
    meta: ru3CTd4HB8hXMeta || {},
    alias: ru3CTd4HB8hXMeta?.alias || [],
    redirect: ru3CTd4HB8hXMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/ru.vue").then(m => m.default || m)
  },
  {
    name: trRUTbW9uiWwMeta?.name ?? "game-league-rules-sb-league-tr",
    path: trRUTbW9uiWwMeta?.path ?? "sb-league-tr",
    meta: trRUTbW9uiWwMeta || {},
    alias: trRUTbW9uiWwMeta?.alias || [],
    redirect: trRUTbW9uiWwMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-league/tr.vue").then(m => m.default || m)
  }
],
    meta: rulesBRkY854yiMMeta || {},
    alias: rulesBRkY854yiMMeta?.alias || [],
    redirect: rulesBRkY854yiMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/game/league/rules.vue").then(m => m.default || m)
  }
],
    meta: leagueq0UtZ6esqoMeta || {},
    alias: leagueq0UtZ6esqoMeta?.alias || [],
    redirect: leagueq0UtZ6esqoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/game/league.vue").then(m => m.default || m)
  },
  {
    name: singletkrLh71bOVMeta?.name ?? "game-single",
    path: singletkrLh71bOVMeta?.path ?? "/game/single",
    children: [
  {
    name: rulesuavGUTDB65Meta?.name ?? "game-single-rules",
    path: rulesuavGUTDB65Meta?.path ?? "rules",
    children: [
  {
    name: en_45GBNAbRHXLQRCMeta?.name ?? "game-single-rules-iihf-single-en-GB",
    path: en_45GBNAbRHXLQRCMeta?.path ?? "iihf-single-en-GB",
    meta: en_45GBNAbRHXLQRCMeta || {},
    alias: en_45GBNAbRHXLQRCMeta?.alias || [],
    redirect: en_45GBNAbRHXLQRCMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: en_45GBNAbRHXLQRCMeta?.name ?? "game-single-rules-iihf-single-en",
    path: en_45GBNAbRHXLQRCMeta?.path ?? "iihf-single-en",
    meta: en_45GBNAbRHXLQRCMeta || {},
    alias: en_45GBNAbRHXLQRCMeta?.alias || [],
    redirect: en_45GBNAbRHXLQRCMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: esqJ1wqXaTyYMeta?.name ?? "game-single-rules-iihf-single-es",
    path: esqJ1wqXaTyYMeta?.path ?? "iihf-single-es",
    meta: esqJ1wqXaTyYMeta || {},
    alias: esqJ1wqXaTyYMeta?.alias || [],
    redirect: esqJ1wqXaTyYMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/es.vue").then(m => m.default || m)
  },
  {
    name: itqvc2rZnZSPMeta?.name ?? "game-single-rules-iihf-single-it",
    path: itqvc2rZnZSPMeta?.path ?? "iihf-single-it",
    meta: itqvc2rZnZSPMeta || {},
    alias: itqvc2rZnZSPMeta?.alias || [],
    redirect: itqvc2rZnZSPMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/it.vue").then(m => m.default || m)
  },
  {
    name: ruVDe7UAW0TZMeta?.name ?? "game-single-rules-iihf-single-ru",
    path: ruVDe7UAW0TZMeta?.path ?? "iihf-single-ru",
    meta: ruVDe7UAW0TZMeta || {},
    alias: ruVDe7UAW0TZMeta?.alias || [],
    redirect: ruVDe7UAW0TZMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/ru.vue").then(m => m.default || m)
  },
  {
    name: trk6DezHnNJvMeta?.name ?? "game-single-rules-iihf-single-tr",
    path: trk6DezHnNJvMeta?.path ?? "iihf-single-tr",
    meta: trk6DezHnNJvMeta || {},
    alias: trk6DezHnNJvMeta?.alias || [],
    redirect: trk6DezHnNJvMeta?.redirect || undefined,
    component: () => import("~/rules/generic/iihf-single/tr.vue").then(m => m.default || m)
  },
  {
    name: eldUxIPReC2MMeta?.name ?? "game-single-rules-last60-single-el",
    path: eldUxIPReC2MMeta?.path ?? "last60-single-el",
    meta: eldUxIPReC2MMeta || {},
    alias: eldUxIPReC2MMeta?.alias || [],
    redirect: eldUxIPReC2MMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/el.vue").then(m => m.default || m)
  },
  {
    name: en_45GBAs4hZPFjbEMeta?.name ?? "game-single-rules-last60-single-en-GB",
    path: en_45GBAs4hZPFjbEMeta?.path ?? "last60-single-en-GB",
    meta: en_45GBAs4hZPFjbEMeta || {},
    alias: en_45GBAs4hZPFjbEMeta?.alias || [],
    redirect: en_45GBAs4hZPFjbEMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: en_45GBAs4hZPFjbEMeta?.name ?? "game-single-rules-last60-single-en",
    path: en_45GBAs4hZPFjbEMeta?.path ?? "last60-single-en",
    meta: en_45GBAs4hZPFjbEMeta || {},
    alias: en_45GBAs4hZPFjbEMeta?.alias || [],
    redirect: en_45GBAs4hZPFjbEMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: esJYGKcVuJEfMeta?.name ?? "game-single-rules-last60-single-es",
    path: esJYGKcVuJEfMeta?.path ?? "last60-single-es",
    meta: esJYGKcVuJEfMeta || {},
    alias: esJYGKcVuJEfMeta?.alias || [],
    redirect: esJYGKcVuJEfMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/es.vue").then(m => m.default || m)
  },
  {
    name: hr3D2RtLpDHgMeta?.name ?? "game-single-rules-last60-single-hr",
    path: hr3D2RtLpDHgMeta?.path ?? "last60-single-hr",
    meta: hr3D2RtLpDHgMeta || {},
    alias: hr3D2RtLpDHgMeta?.alias || [],
    redirect: hr3D2RtLpDHgMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/hr.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROpbzM1JLW4hMeta?.name ?? "game-single-rules-last60-single-ro-RO",
    path: ro_45ROpbzM1JLW4hMeta?.path ?? "last60-single-ro-RO",
    meta: ro_45ROpbzM1JLW4hMeta || {},
    alias: ro_45ROpbzM1JLW4hMeta?.alias || [],
    redirect: ro_45ROpbzM1JLW4hMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROpbzM1JLW4hMeta?.name ?? "game-single-rules-last60-single-ro",
    path: ro_45ROpbzM1JLW4hMeta?.path ?? "last60-single-ro",
    meta: ro_45ROpbzM1JLW4hMeta || {},
    alias: ro_45ROpbzM1JLW4hMeta?.alias || [],
    redirect: ro_45ROpbzM1JLW4hMeta?.redirect || undefined,
    component: () => import("~/rules/generic/last60-single/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: elwsoD03zE8rMeta?.name ?? "game-single-rules-sb-single-el",
    path: elwsoD03zE8rMeta?.path ?? "sb-single-el",
    meta: elwsoD03zE8rMeta || {},
    alias: elwsoD03zE8rMeta?.alias || [],
    redirect: elwsoD03zE8rMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/el.vue").then(m => m.default || m)
  },
  {
    name: en_45GB3WR6YA5xGoMeta?.name ?? "game-single-rules-sb-single-en-GB",
    path: en_45GB3WR6YA5xGoMeta?.path ?? "sb-single-en-GB",
    meta: en_45GB3WR6YA5xGoMeta || {},
    alias: en_45GB3WR6YA5xGoMeta?.alias || [],
    redirect: en_45GB3WR6YA5xGoMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: en_45GB3WR6YA5xGoMeta?.name ?? "game-single-rules-sb-single-en",
    path: en_45GB3WR6YA5xGoMeta?.path ?? "sb-single-en",
    meta: en_45GB3WR6YA5xGoMeta || {},
    alias: en_45GB3WR6YA5xGoMeta?.alias || [],
    redirect: en_45GB3WR6YA5xGoMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/en-GB.vue").then(m => m.default || m)
  },
  {
    name: esKD8StcsnbXMeta?.name ?? "game-single-rules-sb-single-es",
    path: esKD8StcsnbXMeta?.path ?? "sb-single-es",
    meta: esKD8StcsnbXMeta || {},
    alias: esKD8StcsnbXMeta?.alias || [],
    redirect: esKD8StcsnbXMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/es.vue").then(m => m.default || m)
  },
  {
    name: hrSQiymjp4HmMeta?.name ?? "game-single-rules-sb-single-hr",
    path: hrSQiymjp4HmMeta?.path ?? "sb-single-hr",
    meta: hrSQiymjp4HmMeta || {},
    alias: hrSQiymjp4HmMeta?.alias || [],
    redirect: hrSQiymjp4HmMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/hr.vue").then(m => m.default || m)
  },
  {
    name: itY87YSD71H0Meta?.name ?? "game-single-rules-sb-single-it",
    path: itY87YSD71H0Meta?.path ?? "sb-single-it",
    meta: itY87YSD71H0Meta || {},
    alias: itY87YSD71H0Meta?.alias || [],
    redirect: itY87YSD71H0Meta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/it.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROPnJmq1TLXwMeta?.name ?? "game-single-rules-sb-single-ro-RO",
    path: ro_45ROPnJmq1TLXwMeta?.path ?? "sb-single-ro-RO",
    meta: ro_45ROPnJmq1TLXwMeta || {},
    alias: ro_45ROPnJmq1TLXwMeta?.alias || [],
    redirect: ro_45ROPnJmq1TLXwMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: ro_45ROPnJmq1TLXwMeta?.name ?? "game-single-rules-sb-single-ro",
    path: ro_45ROPnJmq1TLXwMeta?.path ?? "sb-single-ro",
    meta: ro_45ROPnJmq1TLXwMeta || {},
    alias: ro_45ROPnJmq1TLXwMeta?.alias || [],
    redirect: ro_45ROPnJmq1TLXwMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/ro-RO.vue").then(m => m.default || m)
  },
  {
    name: ru3Sp0Lw3zucMeta?.name ?? "game-single-rules-sb-single-ru",
    path: ru3Sp0Lw3zucMeta?.path ?? "sb-single-ru",
    meta: ru3Sp0Lw3zucMeta || {},
    alias: ru3Sp0Lw3zucMeta?.alias || [],
    redirect: ru3Sp0Lw3zucMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/ru.vue").then(m => m.default || m)
  },
  {
    name: trFa2QeQkkrzMeta?.name ?? "game-single-rules-sb-single-tr",
    path: trFa2QeQkkrzMeta?.path ?? "sb-single-tr",
    meta: trFa2QeQkkrzMeta || {},
    alias: trFa2QeQkkrzMeta?.alias || [],
    redirect: trFa2QeQkkrzMeta?.redirect || undefined,
    component: () => import("~/rules/generic/sb-single/tr.vue").then(m => m.default || m)
  }
],
    meta: rulesuavGUTDB65Meta || {},
    alias: rulesuavGUTDB65Meta?.alias || [],
    redirect: rulesuavGUTDB65Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/game/single/rules.vue").then(m => m.default || m)
  }
],
    meta: singletkrLh71bOVMeta || {},
    alias: singletkrLh71bOVMeta?.alias || [],
    redirect: singletkrLh71bOVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/game/single.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  }
]