import { getCurrency as intlGetCurrency } from "intl-data";
import type { Currency } from "intl-data";

export interface CurrencyHackOptions extends Partial<Currency> {
  currency: string;
  symbolRegexp: RegExp;
}
type CurrencyHackOptionsMap = Record<string, Record<string, CurrencyHackOptions>>;

export const CurrencyHackData: CurrencyHackOptionsMap = {
  default: {
    MBTC: {
      currency: "BTC",
      symbolRegexp: /BTC/g,
      symbol: "mBTC",
      decimalPlaces: 3,
    },
    USDT: {
      currency: "BTC",
      symbolRegexp: /BTC/g,
      symbol: "USDT",
      decimalPlaces: 3,
    },
  },

  // Asian Logic
  asianlogic: {
    IDR: {
      currency: "IDR",
      symbolRegexp: /IDR/g,
      symbol: "Rp",
    },
    KHR: {
      currency: "KHR",
      symbolRegexp: /KHR/g,
      symbol: "៛",
    },
    LAK: {
      currency: "LAK",
      symbolRegexp: /LAK/g,
      symbol: "₭",
    },
    MMK: {
      currency: "MMK",
      symbolRegexp: /MMK/g,
      symbol: "K",
    },
  },
};

export function getCurrency(code: string, locale: string, provider: string) {
  code = code.toUpperCase();
  let thingy = CurrencyHackData[provider] || CurrencyHackData.default;
  let hack = thingy[code] || CurrencyHackData.default[code];

  if (hack == undefined) return intlGetCurrency(code, locale);

  try {
    let r = intlGetCurrency(hack.currency, locale);
    return { ...r, ...hack };
  } catch (err) {
    return hack;
  }
}
