export default defineProviderPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig();

  nuxtApp.$store.watch((state) => state.provider?.launchProgress, notifyLaunchProgress);
  nuxtApp.$store.watch((state) => state.provider?.launchComplete, onLaunchComplete);

  function onLaunchComplete() {
    const message = {
      name: "loadComplete",
      sender: "game",
    };

    console.debug("Post loadComplete");
    window.parent.postMessage(message, config.hlg.event_origin);

    // Watches for messages that should only be sent after loadComplete sent
    nuxtApp.$store.watch((state) => state.audioMuted, notifyAudioMuted);

    // Sync any state with provider
    notifyAudioMuted(nuxtApp.$store.state.audioMuted);
  }

  // percentage: int 0-100
  function notifyLaunchProgress(percentage: number | undefined) {
    const message = {
      name: "loadProgress",
      sender: "game",
      data: percentage?.toString(),
    };

    console.debug("Post loadProgress: ", percentage);
    window.parent.postMessage(message, config.hlg.event_origin);
  }

  function notifyAudioMuted(muted: boolean) {
    const message = {
      name: "audioToggle",
      sender: "game",
      data: !muted,
    };

    console.debug("Post audioToggle muted = ", muted);
    window.parent.postMessage(message, config.hlg.event_origin);
  }

  // Provider Initiated Event Handlers
  window.addEventListener("message", async (event) => {
    if (!config.hlg.event_origin || event.origin != config.hlg.event_origin) {
      console.warn(`Ignoring received message from unsupported origin ${event.origin}`);
      return;
    }

    if (event.data.sender != "emwindow") {
      console.warn(`Ingoring received message from unsupported sender ${event.data.sender}`);
      return;
    }

    try {
      switch (event.data.name) {
        case "updateBalance":
          console.debug("message: updateBalance");
          await nuxtApp.$store.dispatch("provider/refreshBalance");
          break;
        case "setAudio":
          nuxtApp.$store.commit("setAudioMuted", !event.data.data);
          break;
      }
    } catch (err) {
      console.error(`Error occurred handling event: ${err}`);
    }
  });

  return undefined;
});
