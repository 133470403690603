export const state = () => ({
  custRef: null,
  balance: 0,
});

export const mutations = {
  updateAccount(state, { custRef, balance }) {
    if (custRef !== undefined) state.custRef = custRef;
    if (balance !== undefined) state.balance = balance;
  },
};
