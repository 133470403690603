import moment from "moment-timezone";

export const state = () => ({
  time: null,
  serverTimeOffsetMS: 0,
  currency: {
    code: null,
  },
  app: {
    loadedAt: new Date(),
    // update: false,
  },
  heartbeat: null,
  loginRequired: false,

  audioMuted: false,
  gamePlayDisabled: false,
});

export const mutations = {
  setCurrency(state, currency) {
    state.currency = Object.assign({}, state.currency, currency);
  },

  setTime(state, time) {
    const config = useRuntimeConfig();
    if (state.serverTimeOffsetMS >= Math.abs(config.min_server_time_offset_ms))
      time = moment(time).add(state.serverTimeOffsetMS, "ms").format();

    state.time = time;
  },

  setServerTimeOffset(state, offset) {
    // console.log(`Server Time Offset = ${offset}`)
    state.serverTimeOffsetMS = offset;
  },

  setApp(state, app) {
    state.app = Object.assign({}, state.app, app);
  },

  setHeartbeat(state, beat) {
    state.heartbeat = beat;
  },

  setLoginRequired(state, val) {
    state.loginRequired = val;
  },

  setAudioMuted(state, val) {
    state.audioMuted = val;
  },

  disableGamePlay(state) {
    state.gamePlayDisabled = true;
  },

  enableGamePlay(state) {
    state.gamePlayDisabled = false;
  },
};

export const actions = {
  async nuxtClientInit({ state, commit, dispatch }, nuxtApp) {
    const { client } = useApolloClient();
    const config = useRuntimeConfig();

    commit("setCurrency", { code: config.public.currency });

    let refreshOffset = async () => {
      let {
        data: { clockoffset: offset },
      } = await client.query({
        query: gql`
          query ($time: String) {
            clockoffset(time: $time)
          }
        `,
        variables: {
          time: moment().utc().format(),
        },
        fetchPolicy: "no-cache",
      });
      commit("setServerTimeOffset", offset);
    };

    try {
      await refreshOffset();
      setInterval(refreshOffset, config.public.time_offset_refresh_interval);
    } catch (err) {
      console.warn(`Could not determine clock offset; local time will not be adjusted. BBGQL update required.`);
    }

    commit("setTime", moment().format());

    setInterval(() => {
      commit("setTime", moment().format());
    }, 1000);

    await dispatch("wagerLimits/updateLimits", state.currency.code);

    nuxtApp.$socket.emit("rooms join", { rooms: ["heartbeat"] });

    nuxtApp.$socket.on("reconnect", () => {
      console.log("Reloading due to WS reconnect");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });

    nuxtApp.$socket.on("heartbeat", (msg) => {
      commit("setHeartbeat", msg.at);
    });
  },
};
